import { useState, useReducer } from "react";
import ToDoItem from "./ToDoItem";
import InputArea from "./InputArea";
import "./index.scss";

function ToDoList() {
  const [items, setItems] = useState<string[]>([]);
  const initialTasks: any = [];
  const tasksReducer = (tasks: any, action: any) => {
    switch (action.type) {
      case "added": {
        return [...tasks, action.task];
      }
      case "deleted": {
        return tasks.filter((t: any, index: number) => index !== action.index);
      }
      default: {
        throw Error("Unknown action: " + action.type);
      }
    }
  };

  const [tasks, dispatch] = useReducer(tasksReducer, initialTasks);
  console.log(tasks);

  function addItem(inputText: string) {
    dispatch({
      type: "added",
      task: inputText,
      // text: text,
    });
    // setItems((prevItems) => {
    //   return [...prevItems, inputText];
    //   // add new item to the previous item array
    // });
  }

  function handleDelete(id: number) {
    dispatch({
      type: "deleted",
      index: id,
      // text: text,
    });
    // setItems((prevItems) => {
    //   return prevItems.filter((item, index) => {
    //     return index !== id;
    //     // delete the item that match the id
    //   });
    // });
  }

  return (
    <div className="todo-body">
      <div className="todo-content">
        <div className="todo-heading">
          <h1>To-Do List</h1>
        </div>
        <InputArea buttonClick={addItem} />
        <div>
          <ul>
            {tasks.map((todoItem: string, index: number) => (
              <ToDoItem
                key={index}
                id={index}
                text={todoItem}
                handleDelete={handleDelete}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ToDoList;
